@import '../../../resource/style/default.styl'

@import './fonts.styl'

@keyframes slideUp
  0%
    opacity 0
    transform translateY(20px)

  30%
    opacity 1

  100%
    opacity 1
    transform translateY(0px)

@keyframes spin
  0%
    opacity 0

  100%
    opacity 1

html 
  background var(--ghostY)

body
  background var(--ghostY) // rgba(37,46,55,1) // var(--moon) // radial-gradient(circle, rgba(49,62,75,1) 0%, rgba(33,45,46,1) 100%)
  color var(--mint)
  font-family 'MainFont', sans-serif
  font-weight 600
  font-size 16px
  fill var(--outerspace)
  margin 0
  padding 0
  position relative
  min-width 320px
  max-width 100%
  min-height 100%
  // overflow hidden
  -webkit-font-smoothing antialiased
  --overlayA rgba(130, 0, 100, 0.07) 
  --overlayB rgba(30, 190, 180, 0.07)

  .loadIn
    position absolute
    top 0 
    right 0
    bottom 0 
    left 0
    transition 1s linear opacity
    opacity 0

  .colorOverlay
    position absolute
    top 0 
    right 0
    bottom 0 
    left 0
    background linear-gradient(0deg, var(--overlayA) 0%, var(--overlayB) 100%)
    z-index 9999999999
    pointer-events none
    overflow hidden
    
  input
    background none
    outline none
    border none
    font-weight 300
    font-size 16px
    height 100%
    width 100%
    text-align center
    padding 3px
    box-sizing border-box
    font-family 'FiraCode'
    caret-color var(--good)
    color var(--outerspace)
    // text-shadow 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #0ff, 0 0 80px #0ff, 0 0 90px #0ff, 0 0 100px #0ff, 0 0 150px #0ff
    // text-shadow 0 0 2px #fff, 0 0 8px #fff, 0 0 10px var(--good), 0px 3px 3px black
    position relative

  ::selection
    color var(--ghostX)
    background var(--bad)

  .vibeOverlay
    position absolute
    top 0
    right 0
    bottom 0
    left 0
    z-index 4
    pointer-events none
    border-top 1px solid var(--ghostY)

  .vibeOverlayLit
    background overlayB

  .wingWrap
    position absolute
    top 0px
    bottom 0
    left 50%
    z-index 4
    width 560px
    margin-left -280px
    pointer-events none
    // box-shadow 0px 2px 4px var(--ghostZ)
    // background var(--ghostZ)
    border-left 2px solid var(--ghostY)
    border-right 2px solid var(--ghostY)
    display none
  
  .versionBadge
    position absolute
    font-size 11px
    left 20px
    bottom 17px
    display flex
    color var(--outerspace)
    fill var(--outerspace)
    align-items center
    // font-family 'VCR'
    opacity 0.12
    z-index 30

  .navButton
    position absolute
    right 13px
    bottom 17px
    display flex
    color var(--outerspace)
    fill var(--outerspace)
    align-items center
    // font-family 'VCR'
    text-transform uppercase
    z-index 30

    .navButtonForward
      display flex
      align-items center
      justify-content center
      padding-left 18px
      padding-right 17px
      background var(--ghostB)
      border-bottom 2px solid var(--ghostA)
      border-radius 16px
      margin-left 8px
      cursor pointer
      pointer-events auto
      box-shadow 0px 2px 4px var(--ghostZ)
      height 48px
      min-width 48px
      box-sizing border-box
      transition standardFast

      *
        pointer-events none

    .navButtonForwardGlow
      color var(--moon)

    .navButtonBack
      display flex
      align-items center
      justify-content center
      padding-left 17px
      padding-right 18px
      background var(--ghostB)
      border-bottom 2px solid var(--ghostA)
      border-radius 16px
      cursor pointer
      pointer-events auto
      height 48px
      width 48px
      box-shadow 0px 2px 4px var(--ghostZ)
      box-sizing border-box
      transition standardFast

      *
        pointer-events none

    .navButtonBack:hover, .navButtonForward:hover
      background var(--ghostB)

    .navButtonBack:active, .navButtonForward:active, .navButtonAdvanceActive
      background var(--ghostB)
      box-shadow 0px 0px 2px var(--ghostY)
      transform scale(0.9)

    .enterText
      font-size 10px

    .enterTextHighlight
      font-size 10px

    .enterTextBig
      font-size 15px
      margin 0px 9px 2px 7px

    .enterIcon
      display flex
      align-items center
      justify-content center
      margin-top 0px
      height 40px
      svg
        position relative
        top -8px
       
  .popupError
    position absolute
    bottom 17px
    left 13px
    font-size 18px
    border-radius 16px
    background var(--ghostB)
    color var(--bad)
    max-width 375px
    font-weight 500
    letter-spacing 1px
    padding 15px 18px 17px 19px
    font-family 'VCR'
    text-transform uppercase
    box-sizing border-box
    box-shadow 0px 2px 4px var(--ghostZ)
    border-bottom 2px solid  var(--ghostA)
    z-index 200
    text-align center

  .popupMessage
    color var(--good)

  .headerLogo
    position absolute
    left 13px
    top 17px
    height 48px
    width 100px
    font-size 26px
    font-weight 400
    border-radius 16px
    background var(--ghostB)
    box-shadow 0px 2px 4px var(--ghostZ)
    max-width 400px
    color var(--outerspace)
    fill var(--outerspace)
    border-bottom 2px solid var(--ghostA)
    align-items center
    z-index 3000
    box-sizing border-box
    user-select none
    text-shadow 0px 1px 0px var(--outerspace08), 0px 2px 3px var(--ghostY)

    *
      pointer-events none

    // .glitch
    //   padding-bottom 2px

  .headerAccount
    font-family 'FiraCode'
    font-size 16px
    display flex
    align-items center
    user-select none
    box-shadow 0px 2px 4px var(--ghostZ)
    position absolute
    right 13px
    top 17px
    height 48px
    padding 18px
    font-weight 500
    border-radius 16px
    background var(--ghostB)
    border-bottom 2px solid var(--ghostA)
    color var(--outerspace)
    fill var(--outerspace)
    z-index 30
    box-sizing border-box
    cursor pointer

  #dashboard
    width 100%
    height 100%
    position relative
  
@import '../App/style'


// @media (max-width: 920px)
//   body
//     background yellow
   
// @media (max-width: 720px)
//   body
//     background green

// @media (max-width: 520px)
//   body
//     background blue
